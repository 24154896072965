<script>
import { defineComponent, ref, computed, watch, getCurrentInstance } from 'vue'
import { ValidationObserver } from 'vee-validate'
import { useSaveSectionOnScroll } from '../useSaveSectionOnScroll'
import FormItem from '@/components/Input/FormItem.vue'
import Checkbox from '@/components/Input/Checkbox.vue'
import FormInputGroup from '@/components/Input/FormInputGroup.vue'
import UnitSelector from '../TypeAndPrice/UnitSelector.vue'
import { useUnits } from '../useUnits'
import isEqual from 'lodash/isEqual'

export default defineComponent({
  components: {
    ValidationObserver,
    FormItem,
    Checkbox,
    FormInputGroup,
    UnitSelector,
},
  props: {
    value: {
      type: Array,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $confirm } = getCurrentInstance().proxy.$root

    const { el, dirty, validationObserver } = useSaveSectionOnScroll(emit)

    /** @type {import('@vue/composition-api').Ref<import('@/types/product').ProductFormModel>} */
    const model = ref(props.value)
    watch(() => props.value, (value) => {
      if (!isEqual(model.value, value)) model.value = value
    }, { deep: true })
    watch(() => model.value, (units) => emit('input', units) , { deep: true } )
    // watch(() => model.value[0]?.unit, (value) => {
    //   props.product.catalogs.items.forEach(item => {
    //     if (value && item.price) {
    //       item.price.uom = value
    //     }
    //   })
    // })

    const unitOnCatalogs = computed(() => [ ...new Set(props.product?.catalogs.items.map((item) => item.price.uom)) ])
    // watch(unitOnCatalogs, () => {
    //   unitOnCatalogs.value.forEach(unit => {
    //     addUnit(unit)
    //   })
    // })
    watch(() => unitOnCatalogs.value[0], (uom, oldValue) => {
      if (uom === oldValue) return

      const baseUom = model.value[0].unit

      if (model.value.length > 1 && baseUom !== uom) {
        $confirm({
          title: 'Ubah satuan',
          content: `Jika dirubah, semua satuan tambahan akan dihapus. Apakah anda ingin mengubah satuan dari ${oldValue} ke ${uom}?`,
          okText: 'Ubah Satuan',
          onOk: () => {
            model.value = [
              {
                ...model.value[0],
                unit: uom,
              },
            ]
           },
          onCancel: () => {
            emit('cancelBaseUomChange', oldValue)
          },
        })
      } else {
        model.value[0].unit = uom
      }
    }, { flush: 'pre' })

    const { units } = useUnits()
    const selectedUnit = computed(() => [ ...new Set(model.value.map(u => u.unit)) ])
    const addUnit = (unit) => {
      let unitValue = unit

      if (!unitValue) {
        unitValue = units.value.find(unit => !selectedUnit.value.includes(unit))
      }

      if (model.value.find(item => item.unit === unit)) {
        return
      }

      model.value.push({
        collapseOpen: true,
        unit: unitValue,
        denumerator: 1,
        numerator: 1,
        package: {
          height_unit: 'cm',
          width_unit: 'cm',
          length_unit: 'cm',
          height: null,
          width: null,
          length: null,
          weight: null,
          weight_unit: 'g',
        },
      })
    }
    const removeUnit = (index) => {
      model.value.splice(index, 1)
      dirty.value = true
    }

    return {
      el,
      dirty,
      validationObserver,
      model,
      addUnit,
      removeUnit,
      selectedUnit,
      PACKAGE_SIZES: [
        { value: 'mm', label: 'MM' },
        { value: 'cm', label: 'CM' },
        { value: 'm', label: 'M' },
      ],
    }
  },
})
</script>

<template>
  <a-card ref="el">
    <a-skeleton v-if="loading" active />
    <ValidationObserver v-else ref="validationObserver">
      <h4 class="mb-5">
        {{ $t('product.weight_product_shipping') }}
      </h4>

      <div v-if="$route.query.edit">
        <div style="margin-bottom: 8px; padding-left: 8px;">
          Produk Fisik : {{ product.detail.physical ? 'Ya' : 'Tidak' }}
        </div>
        <div v-for="(unit, index) in model" :key="index" :gutter="16" style="padding: 8px">
          <div style="padding: 12px; font-weight: bold; background: #f0f2f4; border: 1px solid #dee2e6">
            {{ unit.unit }}
          </div>
          <div style="padding: 8px 12px; border: 1px solid #dee2e6; border-top: 0">
            <a-row :gutter="16">
              <a-col :span="12" style="padding: 8px">
                <span style="font-size: 13px; font-weight: 600">Satuan Dasar</span>
                <a-row>
                  <a-col :span="12" style="padding: 8px 0 4px; color: #999">
                    Satuan Produk
                  </a-col>
                  <a-col :span="12" style="padding: 8px 0 4px">
                    : {{ unit.unit }}
                  </a-col>
                  <a-col :span="12" style="padding: 4px 0; color: #999">
                    Satuan Berat
                  </a-col>
                  <a-col :span="12" style="padding: 4px 0">
                    : {{ unit.package.weight_unit }}
                  </a-col>
                  <a-col :span="12" style="padding: 4px 0; color: #999">
                    Nilai Berat
                  </a-col>
                  <a-col :span="12" style="padding: 4px 0">
                    : {{ unit.package.weight }}
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="12" style="padding: 8px">
                <span style="font-size: 13px; font-weight: 600">Ukuran Paket</span>
                <a-row>
                  <a-col :span="12" style="padding: 8px 0 4px; color: #999">
                    Nilai Lebar
                  </a-col>
                  <a-col :span="12" style="padding: 8px 0 4px">
                    : {{ `${unit.package.width} ${unit.package.width_unit}` }}
                  </a-col>
                  <a-col :span="12" style="padding: 4px 0; color: #999">
                    Nilai Panjang
                  </a-col>
                  <a-col :span="12" style="padding: 4px 0">
                    : {{ `${unit.package.length} ${unit.package.length_unit}` }}
                  </a-col>
                  <a-col :span="12" style="padding: 4px 0; color: #999">
                    Nilai Tinggi
                  </a-col>
                  <a-col :span="12" style="padding: 4px 0">
                    : {{ `${unit.package.height} ${unit.package.height_unit}` }}
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>

      <FormItem>
        <Checkbox
          v-if="!$route.query.edit"
          v-model="product.detail.physical"
          :check-value="false"
          :uncheck-value="true"
          label="Produk Non Fisik"
          :disabled="$route.query.edit || $route.query.master_product ? true : false"
          @input="(val) => $emit('input:physical', val)"
        />
      </FormItem>

      <div v-if="product.detail.physical && !$route.query.edit" class="unit-wrapper">
        <div v-for="(unit, index) in model" :key="index" class="unit-item">
          <div
            v-if="index !== 0"
            class="unit-item__header cpoin"
            :class="{ 'unit-item__header--active': unit.collapseOpen }"
            @click.stop="unit.collapseOpen = !unit.collapseOpen"
          >
            <div class="unit-item__header__title">
              {{ unit.unit }}
            </div>
            <div class="actions ml-auto">
              <a-popconfirm :title="`Apakah Anda yakin ingin menghapus satuan ${unit.unit || 'ini'}?`" @confirm="removeUnit(index)">
                <a-button
                  type="primary"
                  size="small"
                  ghost
                  icon="delete"
                  @click.stop=""
                >
                  {{ $t('utils.delete') }}
                </a-button>
              </a-popconfirm>
              <!-- @click="unit.collapseOpen = !unit.collapseOpen" -->
              <a-button class="toggle" ghost>
                <a-icon type="caret-right" />
              </a-button>
            </div>
          </div>
          <div v-if="unit.collapseOpen || index === 0" class="unit-item__body">
            <a-row type="flex" :gutter="16" class="mb-2">
              <a-col v-if="index === 0" :sm="{ span: 12 }" :md="{ span: 8 }" :lg="{ span: 8 }">
                <FormItem label="Satuan Dasar" :name="`Satuan Dasar [${unit.unit}]`" rules="required" immediate>
                  <UnitSelector v-model="model[0].unit" disabled class="h-48px" />
                </FormItem>
              </a-col>

              <template v-else>
                <a-col :sm="{ span: 12 }" :md="{ span: 7 }" :lg="{ span: 6 }">
                  <FormInputGroup
                    tag="div"
                    rules="required|numeric"
                    :label="`Satuan Dasar`"
                    :name="`Satuan Dasar [${unit.unit}]`"
                    label-class="ant-form-item-label mb-1px!"
                    class="h-48px mb-3"
                  >
                    <a-input-number
                      v-model.number="unit.numerator"
                      :min="1"
                      :disabled="$route.query.edit || $route.query.master_product ? true : false"
                      class="h-48px w-100 required"
                    />

                    <template #append>
                      <div class="ant-input-group-addon">
                        {{ model[0].unit }}
                      </div>
                    </template>
                  </FormInputGroup>
                </a-col>
                <a-col class="align-self-center">
                  <div class="pt-3 mt-1">
                    =
                  </div>
                </a-col>

                <a-col :sm="{ span: 12 }" :md="{ span: 8 }" :lg="{ span: 12 }" :xl="{ span: 8 }">
                  <FormInputGroup
                    tag="div"
                    rules="required|numeric"
                    label="Konversi"
                    :name="`Konversi [${unit.unit}]`"
                    label-class="ant-form-item-label mb-1px!"
                    :class="{
                      'has-feedback has-error': !unit.unit,
                    }"
                    class="h-48px mb-3"
                  >
                    <!-- v-model.number="unit.denumerator" -->
                    <a-input-number
                      :value="unit.denumerator"
                      disabled
                      :min="1"
                      class="h-48px w-100 required bg-white"
                    />

                    <template #append>
                      <FormItem
                        class="ant-input-group-addon px-1"
                        style="margin: -2px -11px; width: 120px"
                        rules="required"
                        :name="`Konversi unit [${unit.unit}]`"
                        immediate
                      >
                        <UnitSelector
                          v-model="unit.unit"
                          :disabled="$route.query.edit || $route.query.master_product ? true : false"
                          :excludes="selectedUnit"
                          :available-unit="model"
                          class="d-flex align-items-center"
                        />
                      </FormItem>
                    </template>
                  </FormInputGroup>
                </a-col>
              </template>

              <a-col :sm="{ span: 12 }" :md="{ span: 16 }" :lg="{ span: 16 }" :xl="{ span: 7, offset: index !== 0 ? 1 : 0 }">
                <!-- rules="required|numeric" -->
                <FormInputGroup
                  tag="div"
                  rules="required"
                  :name="`Berat produk [${unit.unit}]`"
                  :label="`Berat produk / ${unit.unit || '-'}`"
                  label-class="ant-form-item-label mb-1px!"
                  class="h-48px mb-3"
                >
                  <a-input-number
                    v-model.number="unit.package.weight"
                    :min="1"
                    :disabled="$route.query.edit || $route.query.master_product ? true : false"
                    class="h-48px w-100 required"
                  />

                  <template #append>
                    <FormItem
                      tag="div"
                      rules="required"
                      :name="`Weight unit [${unit.unit}]`"
                      style="width: 80px; margin: 0 -11px; border-radius: 0 3px 3px 0"
                      class="bg-white ant-input-group-addon px-0"
                    >
                      <a-select 
                        v-model="unit.package.weight_unit"
                        :options="[
                          { value: 'g', label: 'Gram' },
                          { value: 'kg', label: 'Kg' },
                        ]"
                        :disabled="$route.query.edit || $route.query.master_product ? true : false"
                        class="w-100 text-dark"
                      />
                    </FormItem>
                  </template>
                </FormInputGroup>
              </a-col>
            </a-row>

            <label class="mb-2">Ukuran Paket</label>
            <a-row type="flex" :gutter="16">
              <a-col :sm="{ span: 12 }" :md="{ span: 8 }" :lg="{ span: 8 }">
                <FormInputGroup
                  tag="div"
                  rules="required|numeric"
                  :label="`Panjang`"
                  :name="`Panjang [${unit.unit}]`"
                  label-class="floating-label"
                  class="h-48px mb-4"
                >
                  <a-input-number
                    v-model.number="unit.package.length"
                    :min="1"
                    :disabled="$route.query.edit || $route.query.master_product ? true : false"
                    class="h-48px w-100 required"
                  />

                  <template #append>
                    <FormItem
                      tag="div"
                      rules="required"
                      :name="`Satuan Panjang [${unit.unit}]`"
                      style="width: 80px; margin: 0 -11px; border-radius: 0 3px 3px 0"
                      class="bg-white ant-input-group-addon px-0"
                    >
                      <a-select 
                        v-model="unit.package.length_unit"
                        :options="PACKAGE_SIZES"
                        class="w-100 text-dark"
                        :disabled="$route.query.edit || $route.query.master_product ? true : false"
                        @change="val => $emit('changeUkuranPaket', val, index)"
                      />
                    </FormItem>
                  </template>
                </FormInputGroup>
              </a-col>
              <a-col :sm="{ span: 12 }" :md="{ span: 8 }" :lg="{ span: 8 }">
                <FormInputGroup
                  tag="div"
                  rules="required|numeric"
                  :label="`Lebar`"
                  :name="`Lebar [${unit.unit}]`"
                  label-class="floating-label"
                  class="h-48px mb-4"
                >
                  <a-input-number
                    v-model.number="unit.package.width"
                    :min="1"
                    :disabled="$route.query.edit || $route.query.master_product ? true : false"
                    class="h-48px w-100 required"
                  />

                  <template #append>
                    <FormItem
                      tag="div"
                      rules="required"
                      :name="`Satuan Lebar [${unit.unit}]`"
                      style="width: 80px; margin: 0 -11px; border-radius: 0 3px 3px 0"
                      class="bg-white ant-input-group-addon px-0"
                    >
                      <a-select 
                        v-model="unit.package.width_unit"
                        :options="PACKAGE_SIZES"
                        :disabled="$route.query.edit || $route.query.master_product ? true : false"
                        class="w-100 text-dark"
                        @change="val => $emit('changeUkuranPaket', val, index)"
                      />
                    </FormItem>
                  </template>
                </FormInputGroup>
              </a-col>
              <a-col :sm="{ span: 12 }" :md="{ span: 8 }" :lg="{ span: 8 }">
                <FormInputGroup
                  tag="div"
                  rules="required|numeric"
                  :label="`Tinggi`"
                  :name="`Tingg [${unit.unit}]`"
                  label-class="floating-label"
                  class="h-48px mb-4"
                >
                  <a-input-number
                    v-model.number="unit.package.height"
                    :min="1"
                    :disabled="$route.query.edit || $route.query.master_product ? true : false"
                    class="h-48px w-100 required"
                  />

                  <template #append>
                    <FormItem
                      tag="div"
                      rules="required"
                      :name="`Satuan Tinggi [${unit.unit}]`"
                      style="width: 80px; margin: 0 -11px; border-radius: 0 3px 3px 0"
                      class="bg-white ant-input-group-addon px-0"
                    >
                      <a-select 
                        v-model="unit.package.height_unit"
                        :options="PACKAGE_SIZES"
                        class="w-100 text-dark"
                        :disabled="$route.query.edit || $route.query.master_product ? true : false"
                        @change="val => $emit('changeUkuranPaket', val, index)"
                      />
                    </FormItem>
                  </template>
                </FormInputGroup>
              </a-col>
            </a-row>
          </div>
        </div>

        <a-button
          :disabled="$route.query.edit || $route.query.master_product ? true : false"
          ghost
          type="primary"
          icon="plus"
          @click="() => addUnit()"
        >
          Tambah satuan baru
        </a-button>
      </div>
    </ValidationObserver>
  </a-card>
</template>

<style lang="scss">
.floating-label {
  margin-bottom: -0.5rem !important;
  position: relative;
  display: table;
  z-index: 1;
  font-size: 80% !important;
  margin-left: .3rem;
  padding: 0 .35rem;
  background: #fff;
  color: #999;
  font-weight: normal !important;
}
.unit-item__body {
  .ant-input-group {
    &-addon {
      position: relative;

      .has-feedback.has-error {
        .ant-form-item-children-icon {
          display: none;
        }
        .ant-form-explain {
          font-size: 80%;
          position: absolute;
          right: 0;
          margin-top: .25rem;
        }
      }
    }
  }
}

.unit-selector--dropdown .ant-empty-description {
  white-space: normal;
  line-height: 1.2;
  font-size: 12px;
}
</style>

<style scoped lang="scss">
.unit-wrapper {
  > .unit-item:first-child {
    margin-bottom: 2rem;
  }

  > .unit-item:not(:first-child) {
    margin-bottom: 1rem;

    .unit-item__header {
      display: flex;
      background: #F2F4F8;
      border: 1px solid #dedede;
      padding: .5rem 1rem;
      border-radius: .25rem;
      align-items: center;

      &:hover {
        border-color: #495057;
      }
      
      .ant-btn {
        font-size: 90% !important;
      }

      .toggle {
        padding: 0 .25rem;
        color: var(--dark);
        border: 0;
        opacity: .5;
        margin-left: 1rem;
        transition: all .2s ease-in;
      }

      &--active {
        border-radius: .25rem .25rem 0 0;

        .toggle {
          transform: rotate(90deg);
        }
      }
    }

    .unit-item__body {
      border: 1px solid #dedede;
      padding: 1.5rem 2rem 0;
      border-radius: 0 0 .25rem .25rem;
    }
  }
}
.title-detail {
  color: #999999;
  font-weight: 400;
  font-size: 13px;
}
</style>
